import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
const { APP_ENV, SENTRY_PROKEY, SENTRY_PROID } = (window && window.ENV) || {};
if (
  process.env.NODE_ENV === 'production' &&
  SENTRY_PROKEY &&
  SENTRY_PROID
) {
  Sentry.init({
    dsn: `${window.location.protocol}//${SENTRY_PROKEY}@${window.location.host}/sentry/${SENTRY_PROID}`,
    integrations: [new Sentry.BrowserTracing()],
    release: process.env.RELEASE,
    tracesSampleRate: 1.0,
    // 环境变量
    environment: APP_ENV,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
