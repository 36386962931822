import { get, post } from '@/utils/axios';

interface AccountType {
  server_id: string;
  appid: string;
  account: string;
  crm_id: string;
  group: string;
}

export const getAccountList = (data: AccountType): Promise<Http<AccountType[]>> =>
  get('/risk/go/v1/risk_account_setting', data);

export const uptUserAccount = (data: AccountType): Promise<Http<AccountType[]>> =>
  post('/risk/go/v1/risk_account_setting', data);


export const loginOut=(): Promise<Http<any>> =>get('/v2/finpass/logout');

