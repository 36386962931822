import React, { memo, Suspense } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { setValidateLanguage } from '@formily/core';
import enUS from 'antd/lib/locale/en_US';
import GlobalStateProvider from '@/store/GlobalStateProvider';
import DefaultLayout from '@/components/DefaultLayout';
import routes from '@/router.config';
import 'normalize.css';
import './global.less';

setValidateLanguage('en_US');
// 设置message 最多出现数量
message.config({
  maxCount: 2
});

const App: React.FC = () => (
  <GlobalStateProvider>
    <ConfigProvider locale={enUS}>
      <BrowserRouter>
        <Switch>
          {/* <Route path="/login" exact component={Login} /> */}
          <Route
            path="/"
            render={() => (
              <DefaultLayout>
                <Suspense fallback="loading ...">
                  <Switch>
                      <Route path="/" exact>
                        <Redirect to="/risk" />
                      </Route>
                    <Route path="/login" exact>
                      <Redirect to="/risk" />
                    </Route>
                    <Route
                      path="/"
                      render={() =>
                        routes.map((route) => {
                          if (route.children) {
                            return route.children.map(({ path, component }) => (
                              <Route path={path} key={path} exact component={component} />
                            ));
                          }
                          return (
                            <Route
                              path={route.path}
                              exact
                              key={route.path}
                              component={route.component}
                            />
                          );
                        })
                      }
                    />
                    <Redirect to="/risk" />
                  </Switch>
                </Suspense>
              </DefaultLayout>
            )}
          />
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  </GlobalStateProvider>
);

export default memo(App);
