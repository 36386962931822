import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import qs from 'querystring';
// 接口前缀
const BASE_URL = '';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 0
});

// 添加一个请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (config.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params);
      };
    }
    return config;
  },
  function (err) {
    // Do something with request error
    return Promise.reject(err);
  }
);
// 添加一个响应拦截器
instance.interceptors.response.use(
  ({ data: result }) => {
    const {code,data}=result
    if (code == 301) {
      window.location.href = data.redirectUrl;
    }
    return result
  },
  function (err) {
    //  当出现错误时的回调函数。
    // Do something with response error
    //  统一处理 错误信息处
    Promise.reject(err).catch(function (error) {
      // 请求已经发出，但是服务器响应返回的状态吗不在2xx的范围内
      if (error.response) {
        if ([401, 301, 302].includes(error.response.status)) {
      
        }
        if (error.response.status === 400) {
          return;
        }
        if (error.response.status === 404) {
          message.error(`404 not fond: ${error.response.request.responseURL}`);
          return;
        }
        message.error(error.message);
      } else {
        // 一些错误是在设置请求的时候触发
        message.error(error.message);
      }
    });
    //  返回错误信息！
    return Promise.reject(err);
  }
);

export function get<T>(
  url: string,
  params?: { [key: string]: any },
  config?: AxiosRequestConfig
): Promise<T> {
  return instance.get(url, { params, ...config }).then();
}

export function post<T>(
  url: string,
  data?: { [key: string]: any },
  config?: AxiosRequestConfig
): Promise<T> {
  return instance.post(url, data, config).then();
}

export function det<T>(
  url: string,
  data?: { [key: string]: any },
  config?: AxiosRequestConfig
): Promise<T> {
  return instance.delete(url, { data, ...config }).then();
}

export function put<T>(
  url: string,
  data?: { [key: string]: any },
  config?: AxiosRequestConfig
): Promise<T> {
  return instance.put(url, data, { ...config }).then();
}

export function patch<T>(
  url: string,
  data?: { [key: string]: any },
  config?: AxiosRequestConfig
): Promise<T> {
  return instance.patch(url, data, { ...config }).then();
}
