import { createContainer } from 'unstated-next';
import { useState } from 'react';
import { Appid } from '@/api/risk';

const useGlobalState = () => {
  const [appid, setAppid] = useState<Appid[]>([]);
  return { appid, setAppid };
};

export default createContainer(useGlobalState);
