import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Layout, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import routes from '@/router.config';
import './style.less';
import { loginOut } from '@/api/user';
// const { Item: MenuItem, SubMenu } = Menu;

function getItem(
  label: React.ReactNode,
  key?: string,
  icon?: React.ReactNode,
  children?: any[],
  type?: 'group'
) {
  return {
    key,
    icon,
    children,
    label,
    title: label,
    type
  };
}

const DefaultLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const [menuKey, setMenuKey] = useState('/risk');
  const [openKey, setOpenKey] = useState<string[]>([]);
  // 刷新后定位菜单
  useEffect(() => {
    const data = location.pathname;
    if (data) {
      setMenuKey(data);
    }
  }, []);

  const handleSelect = (e: any) => {
    history.push(e.key);

    setMenuKey(e.selectedKeys[0]);
  };

  const handleOpenChange = (keys: any) => {
    setOpenKey(keys as string[]);
  };

  // getItem('Navigation One', 'sub1', <MailOutlined />, [
  //   getItem('Item 1', 'g1', null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
  //   getItem('Item 2', 'g2', null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group')
  // ])
  function items(routeData: any) {
    return routeData?.map((route: any) =>
      // if ()
      getItem(route.title, route.path, route.icon, items(route?.children))
    );
  }
  function clearCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  return (
    <Layout className="ant-layout-has-sider">
      <Sider
        collapsible
        width={256}
        trigger={
          <div
            className="exit"
            style={{ textAlign: 'left', paddingLeft: 25 }}
            onClick={async(e) => {
              clearCookies();
              await loginOut()
            }}
          >
            Exit
          </div>
        }
      >
        <Menu
          // className={style.menu}
          mode="inline"
          theme="dark"
          onSelect={handleSelect}
          selectedKeys={[menuKey]}
          openKeys={openKey}
          onOpenChange={handleOpenChange}
          items={items(routes) as any[]}
        />
      </Sider>
      <Layout>
        <Content style={{ padding: 12, minHeight: '100vh' }}>
          <Card style={{ height: 'calc(100vh - 30px)', minWidth: 1200, overflow: 'hidden' }}>
            {children}
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
