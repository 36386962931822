import React, { ReactNode, lazy } from 'react';
import {
  RiseOutlined,
  // AccountBookOutlined,
  // FullscreenOutlined,
  // AlertOutlined,
  // FileTextOutlined,
  // FileSearchOutlined,
  // FileUnknownOutlined,
  SettingFilled,
  ReadOutlined
} from '@ant-design/icons';
// import Report from '@/pages/report';

const Risk = lazy(() => import('@/pages/risk'));
// const Alert = lazy(() => import('@/pages/alert'));
// const AccountLog = lazy(() => import('@/pages/account-log'));
// const RuleLog = lazy(() => import('@/pages/rule-log'));
// const Adjustment = lazy(() => import('@/pages/adjustment'));
const EndOfDaySetting = lazy(() => import('@/pages/setting/end-of-day'));
const RiskCategory = lazy(() => import('@/pages/risk-category'));
const RiskCategoryReport = lazy(() => import('@/pages/risk-category-report'));
// const UserAccountList = lazy(() => import('@/pages/user/account'));

export interface RouteType {
  path?: string;
  exact?: boolean;
  component?: any;
  title?: string;
  icon?: ReactNode;
  children?: RouteType[];
}

const routes: RouteType[] = [
  // {
  //   title: 'Login & Trading Source  Risk Report',
  //   icon: <AccountBookOutlined />,
  //   component: Report,
  //   path: '/report',
  //   exact: true
  // },
  {
    title: 'Risk Reporting',
    component: Risk,
    icon: <RiseOutlined />,
    path: '/risk',
    exact: true
  },
  // {
  //   title: 'Auto Leverage Adjustment',
  //   icon: <FullscreenOutlined />,
  //   component: Adjustment,
  //   path: '/adjustment',
  //   exact: true
  // },
  // {
  //   title: 'Alert Setting',
  //   component: Alert,
  //   icon: <AlertOutlined />,
  //   path: '/alert',
  //   exact: true
  // },
  // {
  //   title: 'Risk Category Settings',
  //   component: RiskCategory,
  //   icon: <SettingFilled />,
  //   path: '/risk-category-settings',
  //   exact: true
  // },
  {
    title: 'Risk Category',
    icon: <ReadOutlined />,
    children: [
      {
        title: 'Risk Category',
        component: RiskCategoryReport,
        path: '/risk-category-report',
        exact: true
      },
      {
        title: 'Risk Category Settings',
        component: RiskCategory,
        path: '/risk-category-settings',
        exact: true
      }
    ]
  },
  {
    title: 'Setting',
    icon: <SettingFilled />,
    // path: '/user',
    children: [
      {
        title: 'End Of Day Setting',
        component: EndOfDaySetting,
        path: '/end-of-day',
        exact: true
      }
    ]
  }
  // {
  //   title: 'Log',
  //   icon: <FileTextOutlined />,
  //   path: '/log',
  //   children: [
  //     {
  //       title: 'Account Log',
  //       icon: <FileSearchOutlined />,
  //       component: AccountLog,
  //       path: '/account-log',
  //       exact: true
  //     },
  //     {
  //       title: 'Rule Log',
  //       icon: <FileUnknownOutlined />,
  //       component: RuleLog,
  //       path: '/rule-log',
  //       exact: true
  //     }
  //   ]
  // }
];

export default routes;
